<template>
  <div>
    <pibot-mapbox :interactive="false" :styling="styling" :panTo="panTo" />
  </div>
</template>

<script>
export default {
  name: 'pibot-eprs-map',
  components: {
    'pibot-mapbox': () => import('@/components/mapbox/viewer/Mapbox')
  },
  props: {
    coordinates: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    styling: {
      position: 'relative',
      height: '300px'
    }
  }),
  computed: {
    panTo () {
      return {
        layerId: 'eprs-incidents',
        coordinates: this.coordinates,
        zoom: 10
      }
    }
  }
}
</script>
